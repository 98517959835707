@font-face {
  font-family: "Warp";
  src: url('./static/fonts/Warp-Reg.woff2');
}

.App {
  background-color: #10090A;
  font-family: 'Warp', sans-serif;
}

.main-container {
  display: flex;
  flex-direction: column;
}

.header-container {
  display: flex;
  margin: 2rem;
}

.hero-container {
  display: flex;
}

.hero-image {
  width: 100%;
  height: 85vh;
  margin-top: 0rem;
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 2rem;
  border-radius: 2rem;
}

.landsraad-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 1rem;
}

.hero-text {
  position: absolute;
  right: 4rem;
  bottom: 0rem;
  color: #F5E7E4;
  font-size: 3rem;
  text-align: right;
  line-height: 5rem;
  width: 70%;
}

h1 {
  font-weight: normal;
}

@media screen and (max-width: 600px) {
  .hero-text {
    font-size: 1rem;
    line-height: 1.8rem;
    width: 55%;
    bottom: 2rem;
    right: 2rem;
  }

  .hero-image {
    height: 90vh;
    margin-bottom: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
  
  .header-container {
    margin: 1rem;
  }
}